import { graphql } from 'gatsby';
import React from 'react';
import Seo from '../components/Seo';
import Headline from '../components/Headline';
import BlogInfo from '../components/BlogInfo';
import PostListLayout from '../components/PostListLayout';
import NewsletterForm from '../components/NewsletterForm';
import SocialMedia from '../components/SocialMedia';
import TwitterIcon from '../assets/svg/twitter.inline.svg';
import LinkedinIcon from '../assets/svg/linkedin.inline.svg';
import GithubIcon from '../assets/svg/github.inline.svg';
import { SITE_CONFIG } from '../config/constants';

const IndexPage = ({ data, location }) => {
  const { nodes } = data.allMdx;
  const siteName = SITE_CONFIG['siteName'];
  const newsletterFormActionUrl = SITE_CONFIG['newsletterFormActionUrl'];
  const useNewsletterForm = SITE_CONFIG['useNewsletterForm'];
  let newsletterFormContent;

  if (useNewsletterForm) {
    newsletterFormContent = (
      <NewsletterForm
        submitText="Subscribe"
        description="If you want to be updated when a new post is out, sign up!"
        placeholder="Your email address"
        actionUrl={newsletterFormActionUrl}
        thankYouMsg={{
          title: 'Thank you!',
          text: 'You have successfully joined our subscriber list.',
        }}
      />
    );
  }

  return (
    <>
      <Seo title={siteName} />
      <Headline text={{ line1: "Hey, I'm a Gatsby Starter", line2: 'Gatsby developer' }} />
      <BlogInfo
        header="Welcome to my blog."
        description="A Gatsby Starter Project to easily get started with Blog theme. Written by Andreas Eracleous who lives in Cyprus building useful Gatsby things."
      />
      {newsletterFormContent}
      <SocialMedia
        className="mt-3 ml-3 flex justify-end space-x-4"
        text="Follow me on:"
        twitter={{
          label: 'Twitter',
          url: 'https://twitter.com/AndreasFrontDev',
          icon: TwitterIcon,
        }}
        linkedin={{
          label: 'Linkedin',
          url: 'https://cy.linkedin.com/in/andreaseracleous',
          icon: LinkedinIcon,
        }}
        github={{
          label: 'Github',
          url: 'https://github.com/AndreasEracleous',
          icon: GithubIcon,
        }}
      />
      <PostListLayout data={nodes} />
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query SITE_INDEX_QUERY {
    allMdx(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        slug
        frontmatter {
          title
          datetime: date
          date(formatString: "DD MMMM YYYY")
          tags
          slug
          featuredImage {
            childImageSharp {
              gatsbyImageData(
                width: 480
                transformOptions: { cropFocus: CENTER }
                blurredOptions: { width: 100 }
                placeholder: BLURRED
              )
            }
          }
        }
      }
    }
  }
`;
