import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Newsletter = ({
  submitText,
  placeholder,
  description,
  actionUrl,
  thankYouMsg,
  handleSubmittedForm,
}) => {
  const [isSubmited, setSubmit] = useState(false);
  const [formInputs, setFormInputs] = useState({
    email: '',
  });
  let formContent;
  if (!actionUrl) return;

  const handleSubmit = (e) => {
    e.preventDefault();

    const { email } = formInputs;

    //{"success":false,"errors":{"fields":{"email":["The email field is required."]}}}

    const params = `fields[email]=${email}`;
    //&fields[name]=...

    // Send a POST request
    fetch(actionUrl, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8',
      },
      method: 'POST',
      body: params,
    })
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          setSubmit(true);
          if (handleSubmittedForm) {
            handleSubmittedForm(true);
          }
        }
      })
      .catch((error) => console.log(error));
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormInputs({
      ...formInputs,
      [name]: value,
    });
  };

  formContent = (
    <div className="mt-10 flex flex-wrap">
      <form
        className="relative order-last flex w-full max-w-sm rounded-full bg-slate-200 md:order-first"
        onSubmit={handleSubmit}
      >
        <input
          type="email"
          className="form-input w-full rounded-full border-0 bg-slate-200 px-4 py-3 text-slate-600 placeholder:text-slate-600"
          placeholder={placeholder}
          name="email"
          autoComplete="email"
          value={formInputs.email}
          onChange={handleInputChange}
          required
        />

        <button
          className="relative -mr-0.5 ml-auto min-w-fit rounded-full bg-slate-800 py-1 px-4 text-xl text-white hover:bg-blue-500 dark:bg-blue-600 dark:hover:bg-blue-700"
          type="submit"
          aria-label="Submit Button"
        >
          {submitText}
        </button>
      </form>
      <p className="mb-3 ml-3 max-w-xs text-lg font-light leading-tight md:my-auto">
        {description}
      </p>
    </div>
  );
  if (isSubmited && thankYouMsg) {
    const { title, text } = thankYouMsg;
    if (title || text) {
      formContent = (
        <div className="inline-block w-full max-w-lg rounded border border-emerald-500 p-3">
          <p className="mb-0 text-xl font-bold text-slate-800 dark:text-white">{title}</p>
          <p className="mb-0 text-emerald-500">{text}</p>
        </div>
      );
    }
  }

  return formContent;
};

Newsletter.defaultProps = {
  submitText: 'Subscribe',
  description: '',
  placeholder: '',
  actionUrl: '',
  thankYouMsg: {},
  handleSubmittedForm: null,
};

Newsletter.propTypes = {
  submitText: PropTypes.string,
  description: PropTypes.string,
  placeholder: PropTypes.string,
  actionUrl: PropTypes.string,
  thankYouMsg: PropTypes.object,
  handleSubmittedForm: PropTypes.func,
};

export default Newsletter;
