import React from 'react';
import PropTypes from 'prop-types';

const BlogInfo = ({ header, description }) => {
  return (
    <>
      <h2 className="mt-5 mb-4 text-2xl font-light md:text-4xl">{header}</h2>
      <p className="max-w-3xl text-base md:text-2xl">{description}</p>
    </>
  );
};

BlogInfo.defaultProps = {
  header: '',
  description: '',
};

BlogInfo.propTypes = {
  header: PropTypes.string,
  description: PropTypes.string,
};

export default BlogInfo;
